import React from "react";

export const Nosotros = (props) => {
  return (
    <div id="nosotros" className="text-center">
      <div className="container">
        <div className="col-md-10 col-md-offset-1 section-title">
          <h2>Nosotros</h2>
        </div>
        <div className="row">
          <div className="col-md-4">
            <i className="fa fa-check"></i>
            <h3>Misión</h3>
            <p>Ser una Iglesia Cristiana comprometida con su tiempo y su historia, fiel al Señor Jesucristo, amorosa e inclusiva.</p>
          </div>
          <div className="col-md-4">
            {" "}
            <i className="fa fa-eye"></i>
            <h3>Visión</h3>
            <p>Somos llamados/as por Dios Padre, Hijo y Espíritu Santo a:</p>
            <div >
              <ul>
                <li>
                La proclamación del Evangelio y el testimonio de Jesús.
                </li>
                <li>
                El crecimiento integral en el Espíritu.
                </li>
                <li>
                La renovación de comunidades que celebren la vida en Jesús.
                </li>
                <li>
                El servicio solidario.
                </li>
              </ul>
            </div>
          </div>
          <div className="col-md-4">
            {" "}
            <i className="fa fa-group"></i>
            <h3>Valores</h3>
            <p>
            Sustentados en la gracia amorosa y generosa de Dios que da a todos y sin reproche alguno (Santiago 2:7), promovemos:
            </p>
            <div>
              <ul>
                <li>
                Una espiritualidad comprometida con el Reino de Dios y su justicia.
                </li>
                <li>
                  La dignificación de toda persona.
                </li>
                <li>
                El compromiso ético con la creación.
                </li>
                <li>
                Una mayordomía generosa y responsable.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
