import React from "react";
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export const Actividades = (props) => {
  return (
    <div id="actividades" className="text-center">
      <div className="container">
        <div className="section-title">
          <h2>Nuestras Actividades</h2>
          <p>
            Te dejamos algunas actividades y horarios
          </p>
        </div>
        <div className="row">
          <div className="col-md-4">
            <i class="fa-solid fa-church"/>
            <h3>Culto de los domingos</h3>
            <p>Todos los domingos a las 10hs nos reunimos para celebrar la vida y la esperanza que nos da Jesucristo.</p>
          </div>
          <div className="col-md-4">
            <i class="fa-solid fa-church"/>
            <h3>Reuniones de oración y encuentro bíblico</h3>
            <p>Todos los jueves a las 16:30 nos reunimos para orar y estudiar la Biblia.</p>
          </div>
          <div className="col-md-4">
            <i class="fa-solid fa-church"/>
            <h3>Culto de los sábados</h3>
            <p>Cada 15 dias aproximadamente a las 19hs. Consultar que sábados se realiza.</p>
          </div>
          <div className="col-md-4">
            <p> <br></br></p>
          </div>
          </div>
          <div className="row">
          <div className="col-md-4">
            <i class="fa-solid fa-church"/>
            <h3>Encuentro de niños/as y preadolescentes</h3>
            <p>Sábado de 16 a 18hs. Consultar que sábados se realiza.</p>
          </div>
          
          <div className="col-md-4">
            <i class="fa-solid fa-church"/>
            <h3>Encuentro de Jóvenes</h3>
            <p>Sábado luego de los cultos. Consultar fechas.</p>
          </div>
          <div className="col-md-4">
            <i class="fa-solid fa-church"/>
            <h3>Ropero solidario</h3>
            <p>Sábado, a las 18hs, antes del culto.</p>
          </div>
          <div className="col-md-4">
            <i class="fa-solid fa-church"/>
            <h3>Grupo pequeño de jóvenes en UCEL</h3>
            <p>Jueves 11:15hs. Comunicate con nosotros para más información.</p>
          </div>

          {/* {props.data
            ? props.data.map((d, i) => (
                <div key={`${d.name}-${i}`} className="col-md-4">
                  {" "}
                  <i className={d.icon}></i>
                  <div className="service-desc">
                    <h3>{d.name}</h3>
                    <p>{d.text}</p>
                  </div>
                </div>
              ))
            : "loading"} */}
        </div>
      </div>
    </div>
  );
};
