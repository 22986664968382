import React from "react";
export const Ubicacion = (props) => {

  return (
    <div id="ubicacion">
      <div className="container">
        <div className="row">
          <div className="col-xs-12 col-md-6">
            {" "}
            <img src="img/iglesia.png" className="img-responsive" alt="" />{" "}
          </div>
        
          <div className="col-xs-12 col-md-6">
            <div>
              <h2>Ubicación</h2>
              <p>Encontranos en Laprida 1443-Rosario-Santa Fe-Argentina</p>
              <div>
              <iframe
            title="Iglesia Evangelica Metodista"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d418.48019374799424!2d-60.63593232172689!3d-32.95519253271556!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x95b7ab04f6a8a26f%3A0x8bb0742aa9e9b526!2sIglesia%20Evangelica%20Metodista!5e0!3m2!1ses-419!2sar!4v1690529054796!5m2!1ses-419!2sar"
            width="100%"
            height="300px"
            frameBorder="0"
            allowFullScreen
          ></iframe>

              </div>
              
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
