
import React from "react";


export const Contact = (props) => {

  return (
    <div>
      <div id="contact">
        <div className="container">
          {/* <div className="col-md-8"> */}
            <div className="row">
              <div className="section-title">
                <h2>Contacto</h2>
                <h3>
                  Redes de la iglesia y del grupo de jóvenes.
                </h3>
              </div>

                <div className="row">
                  <div className="col-md-3 contactos">
                    <a className="text-white" href="https://www.instagram.com/laresurosario">
                      <i class="fa-brands fa-instagram"></i><br></br>
                      Instagram iglesia @laresurosario
                    </a>
                  </div>
                  <div className="col-md-3 contactos">
                    <a className="text-white" href="https://www.instagram.com/jovenesresu">
                      <i class="fa-brands fa-instagram"></i><br></br>
                      Instagram jóvenes @jovenesresu
                    </a>
                  </div>
                  <div className="col-md-3 contactos">
                    <a className="text-white" href="https://www.facebook.com/profile.php?id=100075913385570">
                      <i class="fa-brands fa-facebook"></i><br></br>
                      Facebook Iglesia <br></br> /profile.php?id=100075913385570
                    </a>
                  </div>
                  <div className="col-md-3 contactos ">
                    <a className="text-white" href="https://www.facebook.com/JovenesMetodistasResurreccion">
                    <i class="fa-brands fa-facebook"></i><br></br>
                      Facebook Jóvenes <br></br> /JovenesMetodistasResurreccion
                    </a>
                  </div>
                </div>

            </div>
          </div>
          {/* <div className="col-md-3 col-md-offset-1 contact-info">
            <div className="contact-item">
              <h3>Contact Info</h3>
              <p>
                <span>
                  <i className="fa fa-map-marker"></i> Address
                </span>
                {props.data ? props.data.address : "loading"}
              </p>
            </div>
            <div className="contact-item">
              <p>
                <span>
                  <i className="fa fa-phone"></i> Phone
                </span>{" "}
                {props.data ? props.data.phone : "loading"}
              </p>
            </div>
            <div className="contact-item">
              <p>
                <span>
                  <i className="fa fa-envelope-o"></i> Email
                </span>{" "}
                {props.data ? props.data.email : "loading"}
              </p>
            </div>
          </div>
          <div className="col-md-12">
            <div className="row">
              <div className="social">
                <ul>
                  <li>
                    <a href={props.data ? props.data.facebook : "/"}>
                      <i className="fa fa-facebook"></i>
                    </a>
                  </li>
                  <li>
                    <a href={props.data ? props.data.twitter : "/"}>
                      <i className="fa fa-twitter"></i>
                    </a>
                  </li>
                  <li>
                    <a href={props.data ? props.data.youtube : "/"}>
                      <i className="fa fa-youtube"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div> */}
        {/* </div> */}
      </div>
      <div id="footer">
        <div className="container text-center">
        <p>
              Creado con &hearts; por{" "}
              <a href={"https://juangonzalez.com.ar"}>Juan Gonzalez</a>
            </p>
            <p>© {new Date().getFullYear()}</p>
        </div>
      </div>
    </div>
  );
};
